"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressesAPI = void 0;
exports.addressesAPI = {
    'listAddresses': {
        path: process.env.GATSBY_PRIOR_API + "/addresses",
        options: {
            method: 'GET',
            credentials: 'include'
        }
    },
    'createAddress': {
        path: process.env.GATSBY_PRIOR_API + "/addresses",
        options: {
            method: 'POST',
            credentials: 'include'
        }
    },
    'updateAddress': {
        path: process.env.GATSBY_PRIOR_API + "/addresses",
        options: {
            method: 'PUT',
            credentials: 'include'
        }
    },
    'deleteAddress': {
        path: process.env.GATSBY_PRIOR_API + "/addresses",
        options: {
            method: 'DELETE',
            credentials: 'include'
        }
    }
};
