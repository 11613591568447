"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentsAPI = void 0;
exports.paymentsAPI = {
    'createSubscription': {
        path: process.env.GATSBY_PRIOR_API + "/payments",
        options: {
            method: 'POST',
            credentials: 'include'
        }
    },
    'getPayments': {
        path: process.env.GATSBY_PRIOR_API + "/payments",
        options: {
            method: 'GET',
            credentials: 'include'
        }
    }
};
