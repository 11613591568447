"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function hexToRgba(hex, alpha) {
    if (alpha === void 0) {
        alpha = 1;
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result)
        return 'rgba(0,0,0,0)';
    var output = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: alpha
    };
    return "rgba(" + output.r + ", " + output.g + ", " + output.b + ", " + alpha + ")";
}
exports.default = hexToRgba;
