"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentMethodsAPI = void 0;
exports.paymentMethodsAPI = {
    'createStripeSetupIntent': {
        path: process.env.GATSBY_PRIOR_API + "/paymentMethods/setupIntent",
        options: {
            method: 'POST',
            credentials: 'include'
        }
    },
    'syncStripePaymentMethods': {
        path: process.env.GATSBY_PRIOR_API + "/paymentMethods/stripe",
        options: {
            method: 'POST',
            credentials: 'include'
        }
    },
    'listPaymentMethods': {
        path: process.env.GATSBY_PRIOR_API + "/paymentMethods",
        options: {
            method: 'GET',
            credentials: 'include'
        }
    },
    'deletePaymentMethod': {
        path: process.env.GATSBY_PRIOR_API + "/paymentMethods",
        options: {
            method: 'DELETE',
            credentials: 'include'
        }
    },
    'setDefaultPaymentMethod': {
        path: process.env.GATSBY_PRIOR_API + "/paymentMethods/setDefault",
        options: {
            method: 'PUT',
            credentials: 'include'
        }
    }
};
