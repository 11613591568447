import React from "react";
import PropTypes from "prop-types";

const TemplateWrapper = ({ children }) => (
  <div>
    <div className="main-wrapper">{children}</div>
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.func
};

export default TemplateWrapper;
