"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userActions = exports.initialUserState = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.object.to-string");
require("core-js/modules/es6.object.assign");
exports.initialUserState = {
    user: undefined,
    bookmarks: []
};
exports.userActions = {
    setUser: function setUser(store, user) {
        if (!store)
            return;
        if (!user) {
            store.setState(Object.assign({}, store.state, {
                user: undefined
            }));
        }
        else {
            user.createdAtTimestamp = Date.parse(user.createdAt.toString());
            if (user.updatedAt)
                user.updatedAtTimestamp = Date.parse(user.updatedAt.toString());
            if (user.currentPeriodEnd)
                user.currentPeriodEndTimestamp = Date.parse(user.currentPeriodEnd.toString());
            store.setState(Object.assign({}, store.state, {
                user: user
            }));
        }
    },
    setBookmarks: function setBookmarks(store, bookmarks) {
        if (!store)
            return;
        store.setState(Object.assign({}, store.state, {
            bookmarks: bookmarks === null || bookmarks === void 0 ? void 0 : bookmarks.map(function (bookmark) {
                return Object.assign({}, bookmark, {
                    metadata: JSON.parse(bookmark.meta || '{title: null, section: null, tags: null, categories: null}')
                });
            })
        }));
    },
    signOut: function signOut(store) {
        // note: this should be called following a DELETE to the API
        if (!store)
            return;
        store.setState(Object.assign({}, store.state, {
            user: undefined,
            bookmarks: undefined
        }));
    }
};
