"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/modules/es6.number.constructor");
/*  All money is stored in cents, not dollars.
 *  This is done to avoid obnoxious floating-point math errors.
 *  The data is always stored in cents; money is adjusted in the view for users.
 */
var money = function money(amountInCents) {
    var amount = !amountInCents ? 0 : Number(amountInCents) * .01;
    return amount.toFixed(2);
};
exports.default = money;
