"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useElementScroll(fps) {
    if (fps === void 0) {
        fps = 60;
    }
    var ref = react_1.useRef();
    var _useState = react_1.useState({
        x: 0,
        y: 0
    }), position = _useState[0], setScrollPosition = _useState[1];
    var fpsInterval = 1000 / fps;
    react_1.useEffect(function () {
        var _ref$current3;
        // Don’t bother doing anything on server-side-rendered sites.
        if (typeof window === 'undefined')
            return;
        var requestRunning = null;
        var then = Date.now();
        var getScrollPosition = function getScrollPosition() {
            var _ref$current, _ref$current2;
            return ref.current ? {
                x: (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.scrollLeft,
                y: (_ref$current2 = ref.current) === null || _ref$current2 === void 0 ? void 0 : _ref$current2.scrollTop
            } : {
                x: 0,
                y: 0
            };
        };
        var handleScroll = function handleScroll() {
            if (requestRunning) {
                window.cancelAnimationFrame(requestRunning);
            }
            requestRunning = window.requestAnimationFrame(function () {
                var now = Date.now();
                var elapsed = now - then;
                if (elapsed > fpsInterval) {
                    then = now - elapsed % fpsInterval;
                    setScrollPosition(getScrollPosition());
                }
            });
        }; // bind scrolling
        (_ref$current3 = ref.current) === null || _ref$current3 === void 0 ? void 0 : _ref$current3.addEventListener('scroll', handleScroll); // unbind scrolling upon dismount to prevent memory leaks
        return function () {
            var _ref$current4;
            return (_ref$current4 = ref.current) === null || _ref$current4 === void 0 ? void 0 : _ref$current4.removeEventListener('scroll', handleScroll);
        };
    }, []);
    var setRef = react_1.useCallback(function (node) {
        if (node) {
            ref.current = node;
        }
    }, []);
    return {
        position: position,
        setRef: setRef,
        ref: ref
    };
}
exports.default = useElementScroll;
