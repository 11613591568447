"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usersAPI = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.iterator");
require("core-js/modules/es6.object.to-string");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.object.assign");
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
// TODO: enforce body for login and register here?
exports.usersAPI = {
    'register': {
        path: process.env.GATSBY_PRIOR_API + "/auth/register",
        options: {
            credentials: 'include',
            method: 'POST'
        }
    },
    'authenticate': {
        path: process.env.GATSBY_PRIOR_API + "/auth/authenticate",
        options: {
            method: 'GET',
            credentials: 'include'
        }
    },
    'login': {
        path: process.env.GATSBY_PRIOR_API + "/auth/request-login-token",
        options: {
            method: 'POST'
        }
    },
    'logout': {
        path: process.env.GATSBY_PRIOR_API + "/auth/logout",
        options: {
            method: 'DELETE',
            credentials: 'include'
        }
    },
    'updateUser': {
        path: process.env.GATSBY_PRIOR_API + "/users",
        options: {
            method: 'PUT',
            credentials: 'include'
        },
        parseInputs: function parseInputs(userData) {
            var createdAtTimestamp = userData.createdAtTimestamp, updatedAtTimestamp = userData.updatedAtTimestamp, currentPeriodEndTimestamp = userData.currentPeriodEndTimestamp, addresses = userData.addresses, etc = _objectWithoutPropertiesLoose(userData, ["createdAtTimestamp", "updatedAtTimestamp", "currentPeriodEndTimestamp", "addresses"]);
            return etc;
        }
    },
    'updateUserProfile': {
        path: process.env.GATSBY_PRIOR_API + "/users/profile",
        options: {
            method: 'PUT',
            credentials: 'include'
        },
        parseInputs: function parseInputs(userData) {
            var dietaryRequirements = userData.dietaryRequirements, roomPreferences = userData.roomPreferences, essentialAmenities = userData.essentialAmenities, flightClass = userData.flightClass, diningStyles = userData.diningStyles, accomodationStyles = userData.accomodationStyles, etc = _objectWithoutPropertiesLoose(userData, ["dietaryRequirements", "roomPreferences", "essentialAmenities", "flightClass", "diningStyles", "accomodationStyles"]);
            return Object.assign({
                dietaryRequirements: JSON.stringify(Array.from(dietaryRequirements || [])),
                roomPreferences: JSON.stringify(Array.from(roomPreferences || [])),
                essentialAmenities: JSON.stringify(Array.from(essentialAmenities || [])),
                flightClass: JSON.stringify(Array.from(flightClass || [])),
                diningStyles: JSON.stringify(Array.from(diningStyles || [])),
                accomodationStyles: JSON.stringify(Array.from(accomodationStyles || []))
            }, etc);
        }
    },
    'refreshUser': {
        path: process.env.GATSBY_PRIOR_API + "/users",
        options: {
            method: 'GET',
            credentials: 'include'
        }
    }
};
