"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationActions = exports.initialNotificationState = void 0;
require("core-js/modules/es6.object.assign");
exports.initialNotificationState = {
    notification: undefined
};
exports.notificationActions = {
    setNotification: function setNotification(store, notification) {
        store.setState(Object.assign({}, store.state, {
            notification: notification
        }));
    }
};
