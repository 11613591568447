"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.match");
/* We are using imgix.com as an asset CDN proxy.
 *
 * We actually do get a "free" CDN via Contentful, but it doesn't swing JP2.
 *
 * IMPORTANT TODO: the identifier should be a env variable
 */
var imgixPath = function imgixPath(path) {
    if (!path.match(/oc8dxvhvd87u/)) {
        return path;
    }
    return path.replace(/\/\/images\.ctfassets\.net\/oc8dxvhvd87u\//, '//prior.imgix.net/');
};
exports.default = imgixPath;
