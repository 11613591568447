"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/modules/es6.regexp.replace");
/*  Usage:
 *    const derp = styled.div`
 *      border: 1px dashed red;
 *      ${mediaQuery('tablet')} { // starting at 768px…
 *        border: 1px dashed blue;
 *      }
 *      ${mediaQuery(768)} { // same as above
 *        border: 1px dashed yellow;
 *      }
 *      ${mediaQuery('tablet', 'max')} { // everything up to (but not including) 768px…
 *        border: 1px dashed yellow;
 *      }
 *
 *    `
 */
const _theme_1 = require("@theme");
var mediaQuery = function mediaQuery(targetScreensize, direction) {
    if (direction === void 0) {
        direction = 'min';
    }
    var adjustedTarget = typeof targetScreensize === 'string' ? parseInt(_theme_1.breakpoint[targetScreensize].replace('px', '')) : targetScreensize;
    if (direction === 'max')
        adjustedTarget -= 1;
    return "@media only screen and (" + direction + "-width: " + adjustedTarget + "px)";
};
exports.default = mediaQuery;
