"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// source: https://github.com/streamich/react-use
// not adding the entire package because, jeez, that's a lot of package
// but might revisit this decision
const react_1 = require("react");
/**
 * read and write url hash, response to url hash change
 */
var useHash = function useHash() {
    var _useState = react_1.useState(function () {
        return typeof window !== 'undefined' ? window.location.hash : '';
    }), hash = _useState[0], setHash = _useState[1];
    var onHashChange = react_1.useCallback(function () {
        setHash(typeof window !== 'undefined' ? window.location.hash : '');
    }, []);
    useLifecycles(function () {
        if (typeof window === 'undefined')
            return;
        window.addEventListener('hashchange', onHashChange);
    }, function () {
        if (typeof window === 'undefined')
            return;
        window.removeEventListener('hashchange', onHashChange);
    });
    var _setHash = react_1.useCallback(function (newHash) {
        if (newHash !== hash) {
            if (typeof window === 'undefined')
                return;
            window.location.hash = newHash;
        }
    }, [hash]);
    return [hash, _setHash];
};
var useLifecycles = function useLifecycles(mount, unmount) {
    react_1.useEffect(function () {
        if (mount) {
            mount();
        }
        return function () {
            if (unmount) {
                unmount();
            }
        };
    }, []);
};
exports.default = useHash;
