"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/modules/es6.object.assign");
const addresses_1 = require("./addresses");
const bookmarks_1 = require("./bookmarks");
const users_1 = require("./users");
const paymentMethods_1 = require("./paymentMethods");
const payments_1 = require("./payments");
var priorAPI = Object.assign({}, addresses_1.addressesAPI, bookmarks_1.bookmarksAPI, users_1.usersAPI, paymentMethods_1.paymentMethodsAPI, payments_1.paymentsAPI);
var PriorApi = function PriorApi(resource, body) {
    var options = priorAPI[resource].options || {
        body: ""
    };
    options.headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': process.env.GATSBY_SITE_BASE_URL || ''
    });
    if (body) {
        options.body = JSON.stringify(!!priorAPI[resource].parseInputs ? priorAPI[resource].parseInputs(body) : body);
    }
    return fetch(priorAPI[resource].path, options);
};
exports.default = PriorApi;
