"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bookmarksAPI = void 0;
exports.bookmarksAPI = {
    'listBookmarks': {
        path: process.env.GATSBY_PRIOR_API + "/bookmarks",
        options: {
            method: 'GET',
            credentials: 'include'
        }
    },
    'createBookmark': {
        path: process.env.GATSBY_PRIOR_API + "/bookmarks",
        options: {
            method: 'POST',
            credentials: 'include'
        }
    },
    'deleteBookmark': {
        path: process.env.GATSBY_PRIOR_API + "/bookmarks",
        options: {
            method: 'DELETE',
            credentials: 'include'
        }
    }
};
