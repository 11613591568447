"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountDropdownActions = exports.initialAccountDropdownState = void 0;
require("core-js/modules/es6.object.assign");
exports.initialAccountDropdownState = {
    panel: 'login',
    visibleDropdown: undefined,
    regBundle: undefined
};
exports.accountDropdownActions = {
    setPanel: function setPanel(store, panel) {
        store.setState(Object.assign({}, store.state, {
            panel: panel
        }));
    },
    setVisibleDropdown: function setVisibleDropdown(store, visibleDropdown) {
        store.setState(Object.assign({}, store.state, {
            visibleDropdown: visibleDropdown
        }));
    },
    setRegBundle: function setRegBundle(store, regBundle) {
        store.setState(Object.assign({}, store.state, {
            regBundle: regBundle
        }));
    }
};
